/* Colors  */
$base-color: #212529;
$text-light: #6E6E6E;
$light-gray: #DEDEDE;
$body-background-color: #F4F5F8;
$theme-color: #0B2A4A;
$active-color: #297994;
$active-light-color: #40A8CB;

$success-color: #2E8559;
$warning-color: #A76811;
$danger-color: #CD493C;

/* Fonts  */
$base-font: Source Sans Pro,sans-serif; /* Base font */
$base-font-weight: normal;
$base-font-style: normal;

$base-font-size: 16px;