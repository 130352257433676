@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Light.eot');
  src: url('../fonts/SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SourceSansPro-Light.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Light.woff') format('woff'),
      url('../fonts/SourceSansPro-Light.ttf') format('truetype'),
      url('../fonts/SourceSansPro-Light.svg#SourceSansPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Regular.eot');
  src: url('../fonts/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SourceSansPro-Regular.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Regular.woff') format('woff'),
      url('../fonts/SourceSansPro-Regular.ttf') format('truetype'),
      url('../fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-SemiBold.eot');
  src: url('../fonts/SourceSansPro-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
      url('../fonts/SourceSansPro-SemiBold.woff') format('woff'),
      url('../fonts/SourceSansPro-SemiBold.ttf') format('truetype'),
      url('../fonts/SourceSansPro-SemiBold.svg#SourceSansPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../fonts/SourceSansPro-Bold.eot');
  src: url('../fonts/SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/SourceSansPro-Bold.woff2') format('woff2'),
      url('../fonts/SourceSansPro-Bold.woff') format('woff'),
      url('../fonts/SourceSansPro-Bold.ttf') format('truetype'),
      url('../fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}