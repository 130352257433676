/*
Function Name : Strip Unit
Description : Used for removing the unit from any number.
Usage : @include strip-unit(16px);
*/
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}
/*
Function Name : Calculate REM
Description : Used for converting pixel size to correspondent rem size.
Usage : @include calculateRem(16px);
*/
@function calculateRem($size) {
  $remSize: #{strip-unit($size) / strip-unit($base-font-size)}rem !important;
  @return #{$remSize};
}
/*
Mixin Name : Font Size
Description : Used for adding font size in REM.
Usage : @include font-size(30px);
*/
@mixin font-size($fontSize) {
  font-size: calculateRem($fontSize);
}
// Max width
$maxwidths: 900;
@mixin max-w {
  @for $i from 1 through $maxwidths {
    .max-w-#{$i} {
      max-width: #{$i / 16}rem !important;
    }
  }
}
@include max-w;
// Max height
$maxwidths: 500;
@mixin max-h {
  @for $i from 1 through $maxwidths {
    .max-h-#{$i} {
      max-height: #{$i}px !important;
    }
  }
}
@include max-h;