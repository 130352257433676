/* Sass file imports  */
@import "./assets/sass/base";
@import '~sweetalert2/dist/sweetalert2.min.css';

/* Custom styles  */
.table .thead-dark th {
  font-weight: $base-font-weight;
  background-color: $theme-color;
  border-color: $theme-color;
  @include font-size(16px);
}
.table {
  td, th {
    padding: 1.7rem 1.3rem;
    &:last-child {
      white-space: nowrap;
      width: 1px;
      text-align: right;
    }
  }
}
nav {
  &.navbar {
    .collapse {
      ul {
        border-right: 1px solid $light-gray;
        li {
          &.active {
            color: $active-color;
          }
        }
      }
    }
    button {
      &.btn {
        border-color: #C0C0C0;
        color: $base-color;
        &:hover {
          background: inherit;
        }
      }
    }
  }
}

.user-img {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
  border: 1px solid #f1f1f1;
}
.profile-links {
  padding: 0;
  margin: 0;
  margin-left: 55px;
  list-style-type: none;
  @media (min-width: 992px) {
    margin-left: 0;
  }
  li {
    margin-bottom: 5px;
    &:last-child {
      margin: 0;
    }
    a {
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 10px;
        background-color: $light-gray;
        border-radius: 100%;
        background-image: url(/assets/images/icon-arrow-right.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center center;
      }
    }
  }
}
.tab-links {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
    border-bottom: 1px solid $light-gray;
    &:last-child {
      border: none;
    }
    a {
      display: block;
      &:hover {
        background-color: $body-background-color;
      }
      h5 {
        color: $active-color;
      }
      p {
        color: $text-light;
      }
    }
    &.active {
      border-color: $active-color;
      a {
        background-color: $active-color;
        color: #FFFFFF;
        h5, p {
          color: #FFFFFF;
        }
      }
    }
  }
}
.upload {
  background-color: #F4F5F8;
  position: relative;
  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }  
}
.uploaded-list {
  .uploaded-list-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    &:last-of-type {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
      border: none;
    }
    .doc-thumbnail {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: contain;
      border: 2px solid $light-gray;
    }
    .action-btn {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &.success {
      .doc-thumbnail {
        border-color: $active-light-color;
      }
    }
  }

  .progress-cont {
    height: 2px;
    width: 100%;
    border-radius: 10px;
    background-color: $light-gray;
    position: relative;
    margin-top: 10px;
    .progress {
      width: 0;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 10px;
      background-color: $active-light-color;
      transition: 0.5s all;
    }
  }
}
#customTabAccordion {
  .card {
    border: none;
    .card-header {
      border: none;
      padding: 0;
      background: none;
      button {
        width: 100%;
        padding: 0;
        color: $base-color;
        text-decoration: none;
        text-align: left;
        &:hover, &:active, &:focus {
          outline: none;
          box-shadow: none;
          text-decoration: none;
        }
        &:after {
          content: "";
          display: block;
          border: none;
          width: 22px;
          height: 14px;
          margin-left: 10px;
          background-image: url(/assets/images/icon-ionic-ios-arrow-right.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto;
          position: absolute;
          top: 5px;
          right: 0;
          transform: rotate(90deg);
          transition: all 0.3s;
        }
        &.collapsed {
          &:after {
            transform: rotate(0);
          }
        }
      }
    }
    .card-body {
      padding: 0;
    }
  }
}
.filter-dropdown {
  .dropdown-toggle {
    background-color: $body-background-color;
    border-radius: 0;
    min-width: 150px;
    height: 38px;
    text-align: left;
    padding-left: 15px;
    padding-right: 25px;
    &:focus {
      box-shadow: none;
    }
    &:after {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
    &.filter-icon {
      min-width: auto;
      width: 38px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:after {
        content: none;
      }
    }
  }
  .dropdown-menu {
    background-color: $body-background-color;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $active-light-color;
    padding: 0;
    margin-top: 1px;
    .dropdown-item {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #FFFFFF;
      &:last-child {
        border: none;
      }
    }
  }
}
// Primeng accordion
.p-accordion-header-link {
  &:focus {
    box-shadow: none !important;
  }
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
  border: none;
  background: none;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border: none;
  background: none;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border: none;
  background: none;
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: none;
}
.p-accordion .p-accordion-content {
  border: none;
  padding: 0;
}
.p-accordion p-accordiontab .p-accordion-tab {
  border-bottom: 1px solid $light-gray;
}
.p-accordion-tab .p-accordion-header .p-accordion-header-link h5 {
  &:after {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-left: 10px;
    background-image: url(/assets/images/icon-ionic-ios-arrow-right.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto;
    transition: all 0.3s;
  }
}
.p-accordion-tab-active .p-accordion-header .p-accordion-header-link h5 {
  &:after {
    transform: rotate(90deg);
  }
}
// Accordion style reset
.p-component {
  font-family: $base-font;
}
.p-accordion .p-accordion-content,
.p-accordion .p-accordion-header .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  color: inherit;
}
.modal-content {
  border-radius: 0;
  .modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}