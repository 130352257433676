/* Global reset  */
html {
  font-size: $base-font-size;
}
body {
  font-size: $base-font-size;
  font-family: $base-font;
  font-weight: $base-font-weight;
  color: $base-color;
  background-color: $body-background-color;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}
a {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
button.disabled, button:disabled {
  cursor: no-drop;
}

/* Header  */
header {
  background: #FFFFFF;
  nav {
    height: 60px;
    @media (min-width: 992px) {
      height: 70px;
    }
  }
}
.header-bg {
  height: 230px;
  background: $theme-color;
}
.custom-dropdown {
  .dropdown-toggle {
    cursor: pointer;
    &:after {
      content: "";
      display: block;
      border: none;
      width: 9px;
      height: 5px;
      margin-left: 10px;
      background-image: url(/assets/images/icon-ios-arrow-down.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto;
    }
  }
}
nav {
  &.navbar {
    button {
      &.navbar-toggler {
        border: none;
        border-radius: 0;
        padding-right: 0;
        border-left: 1px solid $light-gray;
      }
    }
  }
}
#navbarToggler {
  display: none;
  &.open {
    display: block;
    position: absolute;
    top: 100%;
    background: #FFFFFF;
    width: 100%;
    padding: 30px;
    left: 0;
    box-shadow: 0px 5px 5px 0px rgba(#000, 0.05);
    z-index: 9;
  }
}

/* Footer  */
footer h6 {
  @include font-size(16px);
}

/* Global classes  */
.container {
  @media (max-width: 576px) {
    padding: 0;
  }
}
.content-area {
  .container {
    @media (max-width: 576px) {
      overflow-x: hidden;
    }
  }
}
.font-weight-light {
  font-weight: 300;
}
.font-weight-normal {
  font-weight: normal;
}
.font-weight-semibold {
  font-weight: 600;
}
.font-size-xxs {
  @include font-size(12px);
}
.font-size-xs {
  @include font-size(14px);
}
.font-size-sm {
  @include font-size(16px);
}
.font-size-md {
  @include font-size(18px);
}
.font-size-xl {
  @include font-size(25px);
}
.text-theme-color {
  color: $theme-color;
}
.text-active-color {
  color: $active-color;
}
.text-light-color {
  color: $text-light;
}
.bg-theme-color {
  background-color: $theme-color;
}
.content-area {
  margin-top: -190px;
}
.card-box {
  border-top: 4px solid #FFFFFF;
  border-bottom: 1px solid $light-gray;
}
.card-head {
  border-bottom: 1px solid $light-gray;
}
.card-box-active {
  border-top-color: $active-light-color;
}
.card-box-theme {
  border-color: $theme-color;
}
.border-bottom {
  border-color: $light-gray !important;
}
.line-height-normal{
  line-height: normal;
}
.scrollbar {
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    margin-left: 10px;
  }
  &::-webkit-scrollbar {
	  width: 4px;
    height: 4px;
	  background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
	  border-radius: 10px;
	  background-color: $base-color;
  }
}

/* Components  */
.status {
  position: relative;
  font-weight: normal;
  @include font-size(13px);
  display: flex;
  align-items: baseline;
  &:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 3px;
    border-radius: 100%;
    background-color: $light-gray;
  }
  &.new {
    color: $text-light;
    &:before {
      background-color: $text-light;
    }
  }
  &.pending {
    color: $active-color;
    &:before {
      background-color: $active-color;
    }
  }
  &.warning {
    color: $warning-color;
    &:before {
      background-color: $warning-color;
    }
  }
  &.success {
    color: $success-color;
    &:before {
      background-color: $success-color;
    }
  }
  &.danger {
    color: $danger-color;
    &:before {
      background-color: $danger-color;
    }
  }
}
.table-sort {
  display: block;
  width: 12px;
  height: 8px;
  background-image: url(/assets/images/icon-material-arrow-drop-down.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  .btn {
  }
  input[type=file] {
    font-size: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.status-badge {
  color: #FFFFFF;
  background-color: $text-light;
  border-radius: 50px;
  padding: 2px 10px;
  @include font-size(10px);
  &.info {
    background-color: $active-light-color;
  }
  &.success {
    background-color: $success-color;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
// Sweet alert
.swal2-container.swal2-center>.swal2-popup {
  padding: 10px !important;
}
.swal2-icon {
  display: none !important;
}
.swal2-title {
  text-align: left !important;
  padding: 20px !important;
  @include font-size(19px);
}
.swal2-html-container {
  text-align: left !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-top: 0px !important;
  @include font-size(14px);
}
.swal2-actions {
  margin-left: 20px !important;
  margin-top: 10px !important;
  margin-bottom: 20px !important;
}
.swal2-styled {
  margin: 0 !important;
}
.swal2-styled.swal2-confirm {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.swal2-styled.swal2-confirm:focus {
  outline: none !important;
  box-shadow: none !important;
}
.swal2-styled.swal2-cancel {
  background: transparent !important;
  color: $active-light-color !important;
}
.swal2-styled.swal2-cancel:hover {
  color: $active-color !important;
}
.swal2-styled.swal2-cancel:focus {
  outline: none !important;
  box-shadow: none !important;
}
.swal2-close:hover {
  color: $text-light !important;
}
.swal2-close:focus {
  outline: none !important;
  box-shadow: none !important;
}
// Modal
.modal-header {
  border: none;
  display: block;
}
.modal-body {}
.modal-footer {
  border: none;
  button {
    &.btn-link {
      color: $active-light-color !important;
      &:hover {
        text-decoration: none !important;
        color: $active-color !important;
      }
      &:focus {
        text-decoration: none !important;
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }
}
// Buttons
.btn {
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  min-width: 88px;
  &.btn-danger {
    background-color: $danger-color;
    color: #FFFFFF;
  }
  &.btn-outline-danger {
    border-color: $danger-color;
    color: $base-color;
    &:hover {
      background-color: $danger-color;
      color: #FFFFFF;
    }
  }
}